<template>
  <div>
    <el-button
      type="info"
      :disabled="false"
      @click="showAssignProductGroupDialog()"
      round
    >
      {{ JSON.parse(productGroupsNumber).length }}
      Groups
    </el-button>
    <article>
      <section>
        <div class="productGroups_dialogs">
          <el-dialog
            :title="`Assign Product Groups`"
            :visible.sync="isAssignProductGroupModalVisible"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div class="row">
              <div class="col-md-12">
                <hr />
                <!-- {{ productGroupIds }} -->
                <div
                  v-loading="loading"
                  class="mb-5"
                >
                  <!-- {{ JSON.parse(productGroupsNumber) }}<br />
                  {{ checkedProductGroups }} -->
                  <el-checkbox
                    :indeterminate="isIndeterminate"
                    v-model="checkAll"
                    @change="handleCheckAllChange"
                  >Check all</el-checkbox>
                  <div style="margin: 15px 0;"></div>
                  <el-checkbox-group
                    v-model="checkedProductGroups"
                    @change="handlecheckedProductGroupsChange"
                  >
                    <el-checkbox
                      v-for="group in groups"
                      :label="group.product_group_id"
                      :key="group.product_group_id"
                    >{{ group.product_group_name }}</el-checkbox>
                  </el-checkbox-group>
                  <br />
                  <el-button
                    style="background-color: #083628; color: white;"
                    @click="assignProductGroup()"
                  >Save Changes</el-button>
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
// const cityOptions = ["Shanghai", "Beijing", "Guangzhou", "Shenzhen"];
// import ScaleOut from "@/components/backoffice/scale-out-component.vue";
// import { format, parseISO } from "date-fns";
export default {
  components: {
    //ScaleOut,
  },
  data() {
    return {
      loading: false,
      isLoading: false,
      isLoadingError: false,
      isAssignProductGroupModalVisible: false,
      submitting: false,
      groups: [],

      checkAll: false,
      checkedProductGroups: [],
      isIndeterminate: true,
    };
  },
  props: {
    productGroupsNumber: {
      required: true,
      type: String,
    },
    productId: {
      required: true,
      type: Number,
    },
  },

  computed: {
    productGroupIds() {
      return this.groups.map((item) => {
        return item.product_group_id;
      });
    },
  },
  methods: {
    handleClose(done) {
      done();
    },

    showAssignProductGroupDialog() {
      this.isAssignProductGroupModalVisible = true;
      this.checkedProductGroups = JSON.parse(this.productGroupsNumber);
      this.getProductGroups();
    },

    async assignProductGroup() {
      try {
        this.isLoading = true;
        let response = await this.$http.patch(
          `products/assign-group/${this.productId}`,
          {
            product_groups: this.checkedProductGroups,
          }
        );
        if (
          response.data.success &&
          response.data.message == "PRODUCT_ASSIGNED_SUCCESSFULLY"
        ) {
          this.$notify({
            title: "Success",
            message: "Product Assigned Successfully",
            type: "success",
          });
          this.isAssignProductGroupModalVisible = false;
          this.$emit("re-fresh");
          this.getProductGroups();
        } else {
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.$notify({
            title: "Connection failed",
            message: "A network error occurred, please try again.",
            type: "error",
          });
        }
        this.$notify({
          title: "Unable to Assign Product",
          message: "An unexpected Error occurred, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async getProductGroups() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`product-groups`);
        if (
          request.data.success &&
          request.data.message === "PRODUCT_GROUPS_FETCHED_SUCCESSFULLY"
        ) {
          this.groups = request.data.groups;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch groups now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    handleCheckAllChange(val) {
      this.checkedProductGroups = val ? this.productGroupIds : [];
      this.isIndeterminate = false;
    },

    handlecheckedProductGroupsChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.groups.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.groups.length;
    },
  },
};
</script>
<style>
.productGroups_dialogs .el-dialog {
  width: 40%;
}
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .productGroups_dialogs .el-dialog {
    width: 90%;
  }
}
/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .productGroups_dialogs .el-dialog {
    width: 90%;
  }
}
/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .productGroups_dialogs .el-dialog {
    width: 90%;
  }
}
/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .productGroups_dialogs .el-dialog {
    width: 90%;
  }
}
</style>