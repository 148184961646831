<template>
    <div>
      <el-button
        type="info"
        :disabled="false"
        @click="showTheColorsDialog()"
        round
      >{{ numberOfColors }} Colors</el-button>
      <article>
        <section>
          <div class="color_dialogs">
            <el-dialog
              :title="`Colors`"
              :visible.sync="isColorModalVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div class="row">
                <div class="col-md-6">
                  <hr />
                  <div
                    v-loading="loading"
                    class="table-responsive mb-5"
                  >
                    <data-tables
                      :data="colors"
                      :table-props="tableProps"
                      :pagination-props="{ pageColors: [5, 20, 25, 50, 100] }"
                      type="expand"
                      :filters="filters"
                    >
  
                      <div slot="empty">
                        <ScaleOut
                          v-if="isLoading && !loadingError"
                          class="mt-3"
                          :background="'#164B70'"
                        />
                        <div
                          @click="getColors"
                          v-else-if="!isLoading && loadingError"
                          style="cursor: pointer"
                        >
                          Unable to Load Colors Now. Please click here to retry
                        </div>
                        <div v-if="!isLoading && !loadingError">No Colors</div>
                      </div>
  
                      <el-table-column
                        label="#"
                        :show-overflow-tooltip="false"
                        label-class-name="text-center"
                        class-name="text-center"
                        width="80px"
                      >
                        <template slot-scope="scope">
                          <span>{{ scope.$index + 1 }}</span>
                        </template>
                      </el-table-column>
  
                      <el-table-column
                        label="Color Name"
                        :show-overflow-tooltip="false"
                        label-class-name="text-left"
                        class-name="text-left"
                      >
                        <template slot-scope="scope">
                          <span> {{ scope.row.color_name }} </span><br />
                          <small style="font-size: 0.6em;">Add On: {{ formattedDate(scope.row.created_date) }} </small><br />
                          <!-- <small style="font-size: 0.6em;">Updated On: {{ formattedDate(scope.row.updated_date) }} </small> -->
                        </template>
                      </el-table-column>
  
                      <el-table-column
                        label="Actions"
                        :show-overflow-tooltip="false"
                        label-class-name="text-center"
                        class-name="text-center"
                      >
                        <template slot-scope="scope">
                          <el-row style="display: flex; align-items:center; justify-content:center;">
                            <el-tooltip
                              content="Edit"
                              placement="top"
                            >
                              <el-button
                                type="warning"
                                :disabled="false"
                                @click="showEditColorData(scope.row)"
                                icon="el-icon-edit"
                                circle
                              ></el-button>
                            </el-tooltip>
                            <el-tooltip
                              content="Delete"
                              placement="top"
                            >
                              <el-button
                                type="danger"
                                :disabled="false"
                                @click="deleteColor(scope.row.color_id)"
                                icon="el-icon-delete"
                                circle
                              ></el-button>
                            </el-tooltip>
                          </el-row>
                        </template>
                      </el-table-column>
  
                    </data-tables>
                  </div>
                </div>
                <div class="col-md-6">
                  <div v-loading="submitting">
                    <div v-if="color_crud_field == 'add'">
                      <h2 style="font-size:1.5em">Add Color</h2>
                      <hr />
                      <el-form
                        :model="colorForm"
                        :rules="rules"
                        label-position="top"
                        ref="colorForm"
                        class="demo-colorForm2"
                        onSubmit="return false;"
                      >
                        <div class="row">
                          <div class="col-md-12">
                            <el-form-item
                              label="Color Name"
                              prop="color_name"
                            >
                              <el-input
                                v-model="colorForm.color_name"
                                @keyup.enter.native="addColor('colorForm')"
                              ></el-input>
                            </el-form-item>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <el-form-item
                              label="Color Code"
                              prop="color_code"
                            >
                              <el-input
                                v-model="colorForm.color_code"
                                @keyup.enter.native="addColor('colorForm')"
                              ></el-input>
                            </el-form-item>
                          </div>
                        </div>
                        <hr />
                        <el-form-item class="text-center">
                          <el-button
                            style="background-color: #083628; color: white;"
                            @click="addColor('colorForm')"
                          > Add Color</el-button>
                        </el-form-item>
                      </el-form>
                      <div class="text-center">
                        <div class="text-center">
                          <small>© 2024 Acacia Wilderness Mweya, All rights reserved.</small>
                        </div>
                        <div class="text-center">
                          <small>
                            Design by <a
                              href="https://ovalspace.co"
                              style="color: black;"
                            ><strong>Ovalspace</strong></a>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="color_crud_field == 'update'">
                      <div class="d-flex align-items-center justify-content-between">
                        <h2 style="font-size:1.5em">Edit Color</h2>
                        <el-button
                          type="primary"
                          Color="mini"
                          @click="color_crud_field = 'add'"
                        >Back To Add Color<i class="el-icon-arrow-right el-icon-right"></i></el-button>
                      </div>
                      <hr />
                      <el-form
                        :model="colorEditForm"
                        :rules="rules"
                        label-position="top"
                        ref="colorEditForm"
                        class="demo-colorEditForm2"
                        onSubmit="return false;"
                      >
                        <div class="row">
                          <div class="col-md-12">
                            <el-form-item
                              label="Color Name"
                              prop="color_name"
                            >
                              <el-input
                                v-model="colorEditForm.color_name"
                                @keyup.enter.native="editColor('colorEditForm')"
                              ></el-input>
                            </el-form-item>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <el-form-item
                              label="Color Code"
                              prop="color_code"
                            >
                              <el-input
                                v-model="colorEditForm.color_code"
                                @keyup.enter.native="editColor('colorEditForm')"
                              ></el-input>
                            </el-form-item>
                          </div>
                        </div>
                        <hr />
                        <el-form-item class="text-center">
                          <el-button
                            style="background-color: #083628; color: white;"
                            @click="editColor('colorEditForm')"
                          >Edit Color</el-button>
                        </el-form-item>
                      </el-form>
                      <div class="text-center">
                        <div class="text-center">
                          <small>© 2024 Acacia Wilderness Mweya, All rights reserved.</small>
                        </div>
                        <div class="text-center">
                          <small>
                            Design by <a
                              href="https://ovalspace.co"
                              style="color: black;"
                            ><strong>Ovalspace</strong></a>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  import ScaleOut from "@/components/backoffice/scale-out-component.vue";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut,
    },
    data() {
      return {
        loading: false,
        isColorModalVisible: false,
        submitting: false,
        colors: [],
        color_crud_field: "add",
        ColorData: {},
        tableProps: {
            border: true,
            stripe: true,
        },
        filters: [
            {
            prop: "color_name",
            value: "",
            },
        ],
      loadingError: false,
        color_edit_id: "",
        colorForm: {
          color_name: "",
          color_code: "",
        },
        colorEditForm: {
          color_id: "",
          color_name: "",
          color_code: "",
        },
        rules: {
          color_name: [
            {
              required: true,
              message: "Color Name is required",
              trigger: "blur",
            },
          ],
          color_code: [
            {
              required: true,
              message: "Color Code is required",
              trigger: "blur",
            },
          ],
        },
      };
    },
    props: {
      numberOfColors: {
        required: true,
        type: Number,
      },
    productId: {
        required: true,
        type: Number,
    },
    categoryId: {
      required: true,
      type: String,
    },
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
    methods: {
      handleClose(done) {
        done();
      },
  
      showTheColorsDialog() {
        this.isColorModalVisible = true;
        this.getColors();
      },
  
      showEditColorData(ColorData) {
        this.color_crud_field = "update";
        this.ColorData = ColorData;
        this.color_edit_id = ColorData.color_id;
        this.colorEditForm.color_name = ColorData.color_name;
        this.colorEditForm.color_code = ColorData.color_code;
      },
  
      async addColor(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              let response = await this.$http.post(`no-images-colors`, {
                product_id: this.productId,
                color_name: this.colorForm.color_name,
                color_code: this.colorForm.color_code,
                created_by: this.$store.state.userId,
                category_id: this.categoryId
              });
              if (
                response.data.success &&
                response.data.message == "COLOR_CREATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$notify({
                  title: "Success",
                  message: "Color Added Successfully",
                  type: "success",
                });
                //   this.isColorModalVisible = false;
                this.$emit("re-fresh");
                this.getColors();
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Add Color",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
  
      async getColors() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`no-images-colors/${this.productId}`);
          if (request.data.success) {
            this.colors = request.data.colors;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch colors now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async editColor(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              let response = await this.$http.patch(
                `no-images-colors/${this.color_edit_id}`,
                {
                    color_name: this.colorEditForm.color_name,
                    color_code: this.colorEditForm.color_code,
                }
              );
              if (
                response.data.success &&
                response.data.message == "COLOR_UPDATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$notify({
                  title: "Success",
                  message: "Color Updated Successfully",
                  type: "success",
                });
                this.color_crud_field = "add";
                //   this.isColorModalVisible = false;
                this.$emit("re-fresh");
                this.getColors();
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Add Color",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
  
      async deleteColor(color_id) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Color. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`no-images-colors/${color_id}`);
              if (
                request.data.success &&
                request.data.message === "COLOR_DELETED_SUCCESSFULLY"
              ) {
                this.$emit("re-fresh");
                this.getColors();
                this.$notify({
                  title: "Success",
                  message: "Color deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete the Color, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
    },
  };
  </script>
  <style>
  .color_dialogs .el-dialog {
    width: 80%;
  }
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .color_dialogs .el-dialog {
      width: 90%;
    }
  }
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .color_dialogs .el-dialog {
      width: 90%;
    }
  }
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .color_dialogs .el-dialog {
      width: 90%;
    }
  }
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .color_dialogs .el-dialog {
      width: 90%;
    }
  }
  </style>